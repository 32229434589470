@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
html {
  font-size: 16px; /* Set the base font size for the root (html) element */
}

body {
  font-size: 1rem; /* Set the default font size for the body using rems */
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.customDangerBtn {
  background-color: var(--theme-primaryRed) !important;
}

.customCancelBtn {
  background-color: var(--theme-primaryBlack) !important;
}
span#dropdownHoverButton:hover #dropdownHover {
  display: block;
}

@media only screen and (max-width: 600px) {
  #dropdownHover {
    position: fixed;
    left: 25px;
    margin-top: 4%;
    background: white;
    width: 88%;
  }
}

/* .custom-height {
  height: 100%;
} */

.socketBtn {
  background: linear-gradient(
    72.47deg,
    #7367f0 22.16%,
    rgba(115, 103, 240, 0.7) 76.47%
  ) !important;
}

.custom-height {
  max-height: 100%;
  overflow-y: auto; /* Enable scrolling */
}
