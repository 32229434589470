.markdown p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.markdown dl,
.markdown ol,
.markdown ul {
  margin-top: 0;
  margin-bottom: 1em;
}

.markdown > ol,
.markdown > ul {
  padding-left: 2rem;
}

.markdown ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}

.markdown ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}

.markdown > table {
  width: 100%;
  margin-bottom: 1rem;
  /* color: #212529; */
  border-collapse: collapse;
}

.markdown th,
.markdown td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center; /* Center align table cell text */
}

.markdown thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.markdown tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.markdown tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.markdown tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.markdown th,
.markdown td {
  border: 1px solid #dee2e6;
}

.markdown th,
.markdown td {
  padding: 0.3rem;
}
